import { SprinkleKey, sprinkles, Sprinkles } from '@/style/sprinkles.css';

import classNames from 'classnames';

export type SprinklesProps = {
  cx?: Sprinkles;
};

export type UseSprinklesProps = SprinklesProps & {
  className?: string;
};

export const useSprinkles = (values: Sprinkles) => sprinkles(values);

export const useSprinklesProps = <T extends UseSprinklesProps>({
  cx = {},
  ...props
}: T): Omit<T, 'cx'> => {
  const className = classNames(sprinkles(cx), props.className);

  return {
    ...props,
    className,
  };
};

export type EmailSafeSprinkleKeys = Extract<
  SprinkleKey, //
  | `cell${string}`
  | 'padding'
  | `padding${string}`
  | 'margin'
  | `margin${string}`
  | 'border'
  | `border${string}`
  | 'display'
  | 'textAlign'
  | 'verticalAlign'
  | 'color'
  | 'backgroundColor'
  | 'bgColor'
>;

export type EmailSafeSprinkles = Pick<Sprinkles, EmailSafeSprinkleKeys>;

export type WithEmailSprinkles<P = NoProps> = P & { cx?: EmailSafeSprinkles };
