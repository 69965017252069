'use client';

import Add from '@mui/icons-material/AddRounded';
import ArrowBack from '@mui/icons-material/ArrowBack';
import ArrowCircleRight from '@mui/icons-material/ArrowCircleRight';
import ChevronDown from '@mui/icons-material/ArrowDownward';
import ArrowDownward from '@mui/icons-material/ArrowDownwardRounded';
import ArrowForward from '@mui/icons-material/ArrowForward';
import ArrowOutward from '@mui/icons-material/ArrowOutward';
import ChevronUp from '@mui/icons-material/ArrowUpward';
import ArrowUpward from '@mui/icons-material/ArrowUpwardRounded';
import Close from '@mui/icons-material/Close';
import Error from '@mui/icons-material/Error';
import Event from '@mui/icons-material/Event';
import Facebook from '@mui/icons-material/Facebook';
import Instagram from '@mui/icons-material/Instagram';
import Insight from '@mui/icons-material/Lightbulb';
import LinkedIn from '@mui/icons-material/LinkedIn';
import Menu from '@mui/icons-material/Menu';
import Newspaper from '@mui/icons-material/Newspaper';
import Pause from '@mui/icons-material/Pause';
import Play from '@mui/icons-material/PlayArrow';
import Podcasts from '@mui/icons-material/Podcasts';
import Remove from '@mui/icons-material/RemoveRounded';
import Search from '@mui/icons-material/Search';
import Share from '@mui/icons-material/Share';
import Twitter from '@mui/icons-material/Twitter';
import Video from '@mui/icons-material/VideoLibrary';
import Mute from '@mui/icons-material/VolumeOff';
import Unmute from '@mui/icons-material/VolumeUp';
import YouTube from '@mui/icons-material/YouTube';

import Download from '@/components/Assets/icons/download.svg';
import BoxFreshSmartPhone from '@/components/Assets/icons/box-fresh-smartphone.svg';
import BreakItDown from '@/components/Assets/icons/break-it-down.svg';
import BuyUsed from '@/components/Assets/icons/buy-used.svg';
import ChallengerSpirit from '@/components/Assets/icons/challenger-spirit.svg';
import CircularEconomy from '@/components/Assets/icons/circular-economy.svg';
import Connectivity from '@/components/Assets/icons/connectivity.svg';
import Contract from '@/components/Assets/icons/contract.svg';
import CreativeAgility from '@/components/Assets/icons/creative-agility.svg';
import CustomerExperience from '@/components/Assets/icons/customer-experience.svg';
import CustomerSupport from '@/components/Assets/icons/customer-support.svg';
import DamagedDevice from '@/components/Assets/icons/damaged-device.svg';
import Different from '@/components/Assets/icons/different.svg';
import Differentiation from '@/components/Assets/icons/differentiation.svg';
import ElegantSimplicity from '@/components/Assets/icons/elegant-simplicity.svg';
import IncreasedProfitability from '@/components/Assets/icons/increased-profitability.svg';
import Innovation from '@/components/Assets/icons/innovation.svg';
import MarketLeadership from '@/components/Assets/icons/market-leadership.svg';
import NetAdds from '@/components/Assets/icons/net-adds.svg';
import PartsForRepair from '@/components/Assets/icons/parts-for-repair.svg';
import PracticalMagic from '@/components/Assets/icons/practical-magic.svg';
import PremiumUsedDevices from '@/components/Assets/icons/premium-used-devices.svg';
import SellUsedDevices from '@/components/Assets/icons/sell-used-devices.svg';
import SmartphoneUse from '@/components/Assets/icons/smartphone-use.svg';
import Smartwatch from '@/components/Assets/icons/smartwatch.svg';
import SteadySupply from '@/components/Assets/icons/steady-supply.svg';
import Streamline from '@/components/Assets/icons/streamline.svg';
import Sustainability from '@/components/Assets/icons/sustainability.svg';
import Tablet from '@/components/Assets/icons/tablet.svg';
import TakesTenMinutes from '@/components/Assets/icons/takes-ten-minutes.svg';
import TotalAlignment from '@/components/Assets/icons/total-alignment.svg';
import TradeIn from '@/components/Assets/icons/trade-in.svg';
import Upgrades from '@/components/Assets/icons/upgrades.svg';
import WhatsTheCatch from '@/components/Assets/icons/whats-the-catch.svg';

import { SprinklesProps, useSprinklesProps } from '@/style/utils/useSprinkles';
import { SvgIconProps, SvgIconTypeMap } from '@mui/material';
import * as style from './Icon.css';

const customIcons = {
  play: Play,
  arrowOutward: ArrowOutward,
  arrowRight: ArrowForward,
  arrowLeft: ArrowBack,
  chevronDown: ChevronDown,
  chevronUp: ChevronUp,
  menu: Menu,
  pause: Pause,
  mute: Mute,
  unmute: Unmute,
  search: Search,
  close: Close,
  download: Download,
  boxFreshSmartPhone: BoxFreshSmartPhone,
  breakItDown: BreakItDown,
  buyUsed: BuyUsed,
  challengerSpirit: ChallengerSpirit,
  circularEconomy: CircularEconomy,
  connectivity: Connectivity,
  contract: Contract,
  creativeAgility: CreativeAgility,
  customerExperience: CustomerExperience,
  customerSupport: CustomerSupport,
  damagedDevice: DamagedDevice,
  different: Different,
  differentiation: Differentiation,
  elegantSimplicity: ElegantSimplicity,
  increasedProfitability: IncreasedProfitability,
  innovation: Innovation,
  marketLeadership: MarketLeadership,
  netAdds: NetAdds,
  partsForRepair: PartsForRepair,
  practicalMagic: PracticalMagic,
  premiumUsedDevices: PremiumUsedDevices,
  sellUsedDevices: SellUsedDevices,
  smartphoneUse: SmartphoneUse,
  smartwatch: Smartwatch,
  steadySupply: SteadySupply,
  streamline: Streamline,
  sustainability: Sustainability,
  tablet: Tablet,
  takesTenMinutes: TakesTenMinutes,
  totalAlignment: TotalAlignment,
  tradeIn: TradeIn,
  upgrades: Upgrades,
  whatsTheCatch: WhatsTheCatch,
};

const appIcons = {
  arrowUp: ArrowUpward,
  arrowDown: ArrowDownward,
  returnRight: ArrowCircleRight,
  news: Newspaper,
  insight: Insight,
  event: Event,
  podcast: Podcasts,
  video: Video,
  share: Share,
  linkedin: LinkedIn,
  facebook: Facebook,
  twitter: Twitter,
  youtube: YouTube,
  instagram: Instagram,
  minus: Remove,
  plus: Add,
  error: Error,
  ...customIcons,
} as const;

const icons = {
  ...appIcons,
  ...customIcons,
};

export type AppIcons = typeof appIcons;
export type AppIcon = AppIcons[AppIconName];
export type AppIconName = keyof AppIcons;

export type CustomIcons = typeof customIcons;
export type CustomIcon = CustomIcons[CustomIconName];
export type CustomIconName = keyof CustomIcons;

export type Icons = typeof icons;
export type Icon = Icons[IconNames];
export type IconNames = AppIconName | CustomIconName;
export type { IconNames as IconName };

export type IconProps<
  D extends React.ElementType = SvgIconTypeMap['defaultComponent'],
  P = NoProps
> = SvgIconProps<
  D,
  {
    name: IconNames;
    className?: string;
    CustomSvgProps?: React.SVGProps<SVGSVGElement>;
  } & SprinklesProps &
    P
>;
const Icon = ({ name, CustomSvgProps, ...rest }: IconProps) => {
  const Component = icons[name];
  const props = useSprinklesProps(rest);

  const sx = {
    fontSize: style.iconVars.fontSize,
    ...props.sx,
  };

  // If name equals the key of any of the customIcons, render the custom icon
  if (name in customIcons)
    return (
      // <SvgIcon fontSize="inherit" {...props} sx={sx}>
      <Component {...CustomSvgProps} />
      // </SvgIcon>
    );

  return <Component fontSize="inherit" {...props} sx={sx} />;
};

export default Icon;
